<template>
   <TheQuizSec />
</template>

<script>
import TheQuizSec from './TheQuizSec.vue';

//import { doNotLetUserGetBack } from '../doNotLetUserGoBack';

export default {
   components: {
      TheQuizSec,
   },
   created() {
      if (sessionStorage.getItem('userCameFromMain')) {
         //doNotLetUserGetBack('quizs');
         this.$router.push({ name: 'quizs' });
      } else {
         //window.location.replace(location.origin);
      }
   },
};
</script>